import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from '../shared';
import { EventsModule } from './events/events.module';
import { ExperiencePromoterModule } from './experience-promoter/experience-promoter.module';
import { FnbOrderQueueModule } from './fnb-order-queue/fnb-order-queue.module';
import { FnbReservationsModule } from './fnb-reservations/fnb-reservations.module';
import { BundleGroupListModule } from './menus/components/bundle-group-list/bundle-group-list.module';
import { BundleGroupsManageModule } from './menus/components/bundle-groups-manage/bundle-groups-manage.module';
import { BundleListModule } from './menus/components/bundle-list/bundle-list.module';
import { BundleManageModule } from './menus/components/bundle-manage/bundle-manage.module';
import { BundleItemModule } from './menus/components/bundle-item/bundle-item.module';
import { FeesListModule } from './menus/components/fees-list/fees-list.module';
import { FeesManageModule } from './menus/components/fees-manage/fees-manage.module';
import { DiscountContentManageModule } from './menus/components/discount-content-manage/discount-content-manage.module';
import { KitchenMasterDetailsModule } from './menus/components/kitchen-master-details/kitchen-master-details.module';
import { IdDetailContentModule } from './menus/components/id-detail-content/id-detail-content.module';
import { IdDetailContentManageModule } from './menus/components/id-detail-content-manage/id-detail-content-manage.module';
import { DiscountContentModule } from './menus/components/discount-content/discount-content.module';
import { SystemReportsModule } from "./system-reports/system-reports.module";
import { LiftsTrailsActivitiesModule } from "./lifts-trails-activities/lifts-trails-activities.module";
@NgModule({
  imports: [
    EventsModule,
    ExperiencePromoterModule,
    FnbOrderQueueModule,
    SharedModule,
    FeesManageModule,
    DiscountContentManageModule,
    DiscountContentModule,
    IdDetailContentModule,
    IdDetailContentManageModule,
    BundleGroupsManageModule,
    BundleListModule,
    BundleItemModule,
    BundleManageModule,
    BundleGroupListModule,
    FnbReservationsModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    ReactiveFormsModule,
    ButtonsModule.forRoot(),
    FeesListModule,
    KitchenMasterDetailsModule,
    SystemReportsModule,
    LiftsTrailsActivitiesModule
  ],
})
export class ClientModule {}
