import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HammerConfig } from './shared/hammer/hammer.config';
import { QuillModule } from 'ngx-quill';

import {
  CrConfigModule,
  CrIconModule,
  CrExportButtonModule,
  CrExportButtonComponent,
  CrPolicyCheckModule,
  CrPolicyCheckComponent,
  WINDOW_PROVIDERS,
} from './shared';
import { UacModule } from './uac/uac.module';
import { ClientModule } from './client/client.module';
import {
  stateServiceProvider, imageServiceProvider, toastServiceProvider, featureFlagServiceProvider, fileUploadServiceProvider,
} from './ng1-factories';

import app from './app';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    UpgradeModule,
    UacModule,
    ClientModule,
    CrIconModule,
    CrExportButtonModule,
    CrPolicyCheckModule,
    CrConfigModule.forRoot(),
    UIRouterUpgradeModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    QuillModule.forRoot(),
    ReactiveFormsModule,
    ModalModule,
  ],
  entryComponents: [CrExportButtonComponent, CrPolicyCheckComponent],
  providers: [
    featureFlagServiceProvider,
    stateServiceProvider,
    imageServiceProvider,
    toastServiceProvider,
    fileUploadServiceProvider,
    WINDOW_PROVIDERS,

    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
  ],
})
export class AppModule {
  constructor(private upgrade: UpgradeModule) {}

  ngDoBootstrap(): void {
    const bootstrapEl = document.getElementById('app-container');
    this.upgrade.bootstrap(bootstrapEl, [app.name], { strictDi: true });
  }
}
